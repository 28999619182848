import axios from "axios";
import {API_ENDPOINT} from "../../constance";
import {Link} from "react-router-dom";
import Button from "./button";
import Modal from "./modal";
import {useState} from "react";

export default function ToCartButton(props) {

    const [addedToCartModalIsOpen, setAddedToCartModalIsOpen] = useState(false)
    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)

    const AddToCart = () => {
        const token = localStorage.getItem('access_token')
        const data = {
            'productItem': props.item,
            'productSize': document.querySelector(`#${props.sizeSelector}`).value
        }
        axios.post(API_ENDPOINT + "product/cart/", data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setAddedToCartModalIsOpen(true)
                props.updateInspectionAmount()
            })
            .catch((err) => {
                console.log(props.updateInspectionAmount)
                console.log(err)
                setErrorModalIsOpen(true)
                localStorage.clear()
            })
    }

    return (
        <>
            <button onClick={AddToCart} id={props.buttonId}
                    className={'p-2 bg-black text-white min-w-[150px] mt-5 mb-5'}>
                Добавить в корзину
            </button>

            <Modal isOpen={addedToCartModalIsOpen} onClose={() => {
                setAddedToCartModalIsOpen(false)
            }} title={'Товар добавлен'}>
                <p>Товар добавлен в вашу корзину.</p>
                <Link to={'/cart'}>
                    <Button>
                        Перейти в корзину
                    </Button>
                </Link>
            </Modal>

            <Modal isOpen={errorModalIsOpen} onClose={() => {
                setErrorModalIsOpen(false)
            }} title={'Ошибка'}>
                <p>Простите, но, похоже, что произошла ошибка. Проверьте доступность товара или попробуйте еще раз.</p>
            </Modal>
        </>
    )
}