import HeadMenu from "../components/ui/headmenu";
import ProductPreview from "../components/product_preview";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_ENDPOINT} from "../constance";
import Footer from "../components/ui/footer";
import Button from "../components/ui/button";

export default function Newest() {

    const [catalog, setCatalog] = useState([])
    const [catalogContent, setCatalogContent] = useState([])
    const [catalogPage, setCatalogPage] = useState(1)
    const [moreLoader, setMoreLoader] = useState(false)

    const GetCatalog = () => {
        setCatalog(null)
        setCatalogContent([])

    }

    useEffect(() => {
        setCatalog(null)
        // alert(filters)
        let apiCall = API_ENDPOINT + `catalog/?newest=true&page=1`

        axios.get(apiCall)
            .then((resp) => {
                console.log(resp.data)
                console.log(resp.data.catalog)
                setCatalogContent([...catalogContent, ...resp.data.catalog])
            })

    }, []);

    useEffect(() => {
        GetCatalogPage()
    }, [catalogPage])

    function GetCatalogPage() {
        setMoreLoader(true)

        let apiCall = ''


        apiCall = API_ENDPOINT + `catalog/?newest=true`


        apiCall += `&page=${catalogPage}`


        axios.get(apiCall)
            .then((resp) => {
                console.log(resp.data)
                console.log(resp.data.catalog)
                setCatalogContent([...catalogContent, ...resp.data.catalog])
                setMoreLoader(false)
            })
    }

    return (
        <div>
            <div className="App container m-auto z-10">
                <HeadMenu/>
                <div className={'text-start max-lg:p-5'}>
                    <h1 className={'text-4xl'}>Новинки текущего сезона</h1>
                    <p>Здесь вы можете ознакомиться с новыми предложениями и коллекциями этого сезона</p>
                </div>
                <div className={'max-lg:w-full text-start max-lg:p-5 catalog mt-5'}>
                    {
                        catalogContent.length > 0 ? (
                            <div>
                                <div className={'grid grid-cols-4 max-lg:grid-cols-2 max-lg:grid-cols-1 gap-4'}>
                                    {
                                        catalogContent.map((d) =>
                                            <ProductPreview productData={d}/>
                                        )
                                    }
                                </div>
                                {
                                    moreLoader ? (
                                        <div className={'w-full flex justify-center items-center flex-col p-20'}>
                                            Загружаем еще <i className='bx bx-loader-alt bx-spin'></i>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button fullw={true} action={() => {
                                                setCatalogPage((prev) => prev + 1)
                                            }}>Загрузить еще</Button>
                                        </div>
                                    )
                                }

                            </div>
                        ) : (
                            <div className={'w-full flex justify-center items-center flex-col'}>
                                Загружаем каталог <i className='bx bx-loader-alt bx-spin'></i>
                            </div>
                        )
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}